.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff;
}

.hero {
  position: relative;
  aspect-ratio: 16 / 9;
  width: 100%;
  min-height: 400px;
  background-image: url('./images/campbkg.png');
  background-size: cover;
  background-position: center;
  background-color: #8cdd3f;
  overflow: hidden;
}

.cloud1,
.cloud2,
.cloud3 {
  position: absolute;
  will-change: transform, opacity;
  opacity: 0;

  height: 50px;
  width: 100px;
}

.sun {
  position: absolute;
  top: 0;
  right: 0;

  width: 25vw;
  height: 25vw;
}

.cloud1 {
  top: 100px;
  transform: translateX(-50px);

  animation: wind1 24s infinite linear 0s forwards;
}

.cloud2 {
  top: 10px;
  transform: translateX(30px);

  animation: wind2 16s infinite linear 2.5s forwards;
}

.cloud3 {
  top: 5%;
  transform: translateX(50px);

  animation: wind3 14s infinite linear -4s forwards;

  width: 200px;
  height: 100px;
}

@keyframes wind1 {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
    transform: translateX(100vw);
  }
}

@keyframes wind2 {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
    transform: translateX(1000px);
  }
}

@keyframes wind3 {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
    transform: translateX(200px);
  }
}

section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 25px;
}

section.context {
  background-color: #8cdd3f;
}

section.context > div {
  width: 90%;
  max-width: 400px;
}

.transition {
  background: linear-gradient(#8cdd3f, white);
  height: 75px;
  width: 100%;
}

section.registration {
  padding-top: 25px;
  background-color: white;
}

section.registration > div {
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.registration h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  text-align: center;
}

.registration .preTitle {
  font-size: 0.6em;
  display: flex;
  align-items: center;
}

.registration img {
  height: 1.5em;
  margin-right: 0.5ch;
}

.rsvpMessage {
  margin: 25px 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1em;
  width: 100%;
}

.checkboxInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  font-size: 1rem;
  margin: 10px 0 25px;
}

.checkboxRoot {
  all: unset;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #5a533d;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-right: 1ch;
}

.checkboxIndicator {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxIndicator > svg {
  width: 75%;
  height: 75%;
}

input {
  border: none;
  border-bottom: 1px solid var(--textColor);
  border-radius: 0;

  width: 65%;
  margin-bottom: 10px;

  text-align: center;
  font-size: 1.25rem;
  font-family: 'Montserrat';
  font-weight: bold;
}

input::placeholder {
  text-align: center;
  font-size: 1.25rem;
  font-family: 'Montserrat';
  font-weight: normal;
}

.inputError {
  font-style: italic;
  color: rgb(216, 0, 0);
  margin-bottom: 5px;
}

.disclaimer {
  font-style: italic;
  font-size: 0.75rem;
  margin-bottom: 25px;
}

.submitButton {
  all: unset;
  cursor: pointer;

  border-radius: 12px;
  border: 2px solid var(--textColor);
  padding: 10px 20px;

  background: linear-gradient(45deg, #34cb35, #d7ec48);

  font-family: 'Lilita One';
  font-weight: normal;
  font-size: 1.5rem;
}

.formResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  margin-top: 25px;
}

.loadingIcon {
  animation: 1s linear infinite spin;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 25px;
  height: 25px;
}

.resultMessage {
  margin: 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
